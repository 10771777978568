/* style.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

:root {
	--text-color: #f5f5f5;
	--hover-color: #c7755c;
	--bg-color: #250821;
	--second-bg-color: #292e33;
	--s-bg: #1f1f21;
	--tealcolor: #017d7c;
	--new-bg-color: #121212;
	--new-text-secondary: #9b9b9b;
	--border-custom: #333333;
	--primary: #5c9efb80;
	--first-primary: #5c9efb;
	--bg-s-theme: #3b2929;
	--text-white: #848484;
	--card-bg: #171717;
}

.card-bg {
	background-color: var(--card-bg) !important;
}

.second_bg {
	background-color: var(--s-bg);
}

.custom-text-white {
	color: var(--text-white) !important;
}

.text-black-custom {
	color: var(--card-bg) !important
}

.custom-text-white {
	height: 72px;
}

.bg-theme-transparent {
	background-color: var(--bg-s-theme);
}

.border-color {
	color: var(--border-custom);
}

.newbg-black {
	background-color: var(--new-bg-color);
}

.teal {
	color: var(--tealcolor) !important
}

.bg-teal {
	background-color: var(--tealcolor) !important;
}


.new-text-secondary {
	color: var(--new-text-secondary);
}

.animate-pulse-bootstrap {
	animation: pulse-bootstrap 1.5s infinite;
}

.text-gradient {
	display: inline-block;
	background: linear-gradient(90deg, var(--first-primary), var(--tealcolor));
	background-clip: text;
	-webkit-background-clip: text;
	/* Pour Chrome et Safari */
	-webkit-text-fill-color: transparent;
	/* Pour Chrome et Safari */
}

.custom-fontWeight {
	font-weight: 800;
}

@keyframes pulse-bootstrap {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

/* .breadcrumb-item+.breadcrumb-item::before {
	color: #dee2e6;
} */


/****Text-area****/
.message-box {
	display: flex;
	align-items: flex-end;
	border: 1px solid #565e64;
	padding-right: 10x;
	padding-left: 10px;
	padding-top: 4px;
	padding-bottom: 4px;
	background-color: #1f1f21;
	position: relative;
}

.imgFluid {
	height: 20px !important;
}

.costom-control {
	max-width: 97%;
	max-height: none;
	word-wrap: break-word;
	text-overflow: ellipsis;
}

.message-box textarea:focus {
	outline: none;
}

/********/
.App {
	text-align: center;
}

.custom_parent_area {
	position: absolute;
	right: 0;
	bottom: -16.5%;
	left: 0;
	z-index: 1030;
}

/* .dhx_diagram_item.dhx_diagram_line {} */

.custom-font {
	font-family: 'Roboto', sans-serif;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.color {
	background-color: #1f1f21;
}

.App-link {
	color: #61dafb;
}

.padding {
	padding-left: 15px;
	padding-right: 15px;
}

.form-control::placeholder {
	color: white;
	padding-left: 50px;
}

.bg-inputSearch {
	background-color: #424242;
}

.dropdown-toggle::after {
	display: none;
}

.dropup .dropdown-toggle::after {
	display: none;
}

/* navlayout */

.dropend .dropdown-toggle::after {
	display: none;
}

.dropstart .dropdown-toggle::before {
	display: none;
}

.diagram-item .dropdown_icon {
	color: #1f1f21;
}

.diagram-item:hover .dropdown_icon {
	color: white;
}

.interview-item .dropdown_icon {
	color: #1f1f21;
}

.interview-item:hover .dropdown_icon {
	color: white;
}

/*----------------->scroll All*/

::-webkit-scrollbar {
	width: .3rem;
	height: .2rem;
}

::-webkit-scrollbar-track {
	background: var(--second-bg-color);
}

::-webkit-scrollbar-thumb {
	background: var(--hover-color);
	border-radius: 5rem;
}

.description::-webkit-scrollbar {
	display: none;
}

#dashlayout::-webkit-scrollbar {
	display: none;
}

.controleScroll::-webkit-scrollbar {
	height: .7rem;
}

#diagram ::-webkit-scrollbar,
#editor ::-webkit-scrollbar {
	width: .5rem;
	height: .7rem;
}

#auth::-webkit-scrollbar,
#navcontainer::-webkit-scrollbar {
	display: none;
}

.tableProcess::-webkit-scrollbar-thumb {
	background: var(--tealcolor) !important;
	border-radius: 5rem;
}

/*----------------->scroll TextArea*/

#textarea::-webkit-scrollbar {
	width: 0.2rem;
}

#textarea::-webkit-scrollbar-track {
	background: transparent;
	padding: 10;
}

#textarea::-webkit-scrollbar-thumb {
	background: var(--hover-color);
	border-radius: 5rem;
}

/****App selector****/

.custom-input:focus {
	outline: none;
	box-shadow: none;
	border: none;
}

.diagram-component {
	width: 100%;
	height: 100%;
	border: solid 1px rgb(0, 0, 0);
	background-color: rgb(255, 255, 255);
}

.text-control {
	max-width: "1OO%";
	max-height: none;
	word-wrap: break-word;
	text-overflow: ellipsis;
}

.hov:hover {
	color: white;
}

.customHeightProcess {
	height: 110px;
}

.customHeightDiagram {
	height: 145px;
}

.customHeightInterview {
	height: 155px;
}

.cardProcess {
	width: 100%;
	max-width: 100%;
	height: 200px;
	max-height: 200px;
	border-radius: 0.75rem;
	border: 1px solid var(--border-custom);
	display: block;
}

.cardProcess:hover {
	border: 1px solid var(--primary);
}

.border-custom {
	border: 1px solid var(--border-custom);
}

.containerCardProcess {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(381.55px, 1fr));
	gap: 1.5rem;
}

.viewall:hover {
	text-decoration: underline !important;
}

.badge {
	--bs-badge-font-weight: 0;
}

/* process interview chatbot ,content question */
.content-container .edit-btn {
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.3s ease;
}

.content-container:hover .edit-btn {
	opacity: 1;
	pointer-events: auto;
}

/****Media query***/

@media (max-width: 575.98px) {
	.custom_width_area {
		width: 100%;
	}

	.m-none {
		display: none;
	}

	.width {
		width: 100%;
	}

	.mt-mob {
		margin-top: 150px;
	}

	.custom-vh {
		height: 76vh;
	}

	.custom-marginLeft {
		margin-left: 78%;
	}

	.custom-width-container {
		width: 100%;
	}

	.maxheight {
		height: 100;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.m-none {
		display: none;
	}

	.custom_width_area {
		width: 100%;
	}

	.mt-mob {
		margin-top: 150px;
	}

	.width {
		width: 100%;
	}

	.custom-vh {
		height: 76.5vh;
	}

	.custom-marginLeft {
		margin-left: 80%;
	}

	.custom-width-container {
		width: 100%;
	}

	.maxheight {
		height: 100;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.custom_fontSize {
		font-size: .6rem;
	}

	.custom_width_area {
		width: 90%;
	}

	.web-none {
		display: none;
	}

	.centerPosition {
		align-items: center;
		justify-content: center;
		margin-top: 120px;
	}

	.width {
		width: 75%;
	}

	.custom__margin {
		margin-left: 285px;
	}

	.margin-left {
		margin-left: 100px;
	}

	.custom-vh {
		height: 74.5vh;
	}

	.custom-marginLeft {
		margin-left: 94%;
	}

	.custom-padding-top {
		margin-top: 150px;
		padding-left: 65px;
	}

	.custom-width-container {
		width: 75%;
	}

	.maxheight {
		height: 150;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.custom_fontSize {
		font-size: .6rem;
	}

	.custom_width_area {
		width: 50%;
	}

	.web-none {
		display: none;
	}

	.width {
		width: 75%;
	}

	.custom__margin {
		margin-left: 285px;
	}

	.centerPosition {
		align-items: center;
		justify-content: center;
		margin-top: 120px;
	}

	.margin-left {
		margin-left: 100px;
	}

	.custom-vh {
		height: 74.6vh;
	}

	.custom-marginLeft {
		margin-left: 94%;
	}

	.custom-padding-top {
		margin-top: 150px;
		padding-left: 65px;
	}

	.custom-width-container {
		width: 75%;
	}

	.maxheight {
		height: 250;
	}
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
	.custom_fontSize {
		font-size: .7rem;
	}

	.custom_width_area {
		width: 50%;
	}

	.web-none {
		display: none;
	}

	.centerPosition {
		align-items: center;
		justify-content: center;
		margin-top: 120px;
	}

	.custom__margin {
		margin-left: 285px;
	}

	.width {
		width: 75%;
	}

	.margin-left {
		margin-left: 100px;
	}

	.custom-vh {
		height: 75vh;
	}

	.custom-marginLeft {
		margin-left: 94%;
	}

	.custom-padding-top {
		margin-top: 150px;
		padding-left: 65px;
	}

	.custom-width-container {
		width: 75%;
	}

	.maxheight {
		height: 250;
	}
}

@media (min-width: 1400px) {
	.custom_fontSize {
		font-size: .9rem;
	}

	.custom_width_area {
		width: 50%;
	}

	.custom__margin {
		margin-left: 285px;
	}

	.web-none {
		display: none;
	}

	.centerPosition {
		align-items: center;
		justify-content: center;
		margin-top: 120px;
	}

	.width {
		width: 75%;
	}

	.margin-left {
		margin-left: 100px;
	}

	.custom-vh {
		height: 76vh;
	}

	.custom-marginLeft {
		margin-left: 94%;
	}

	.custom-padding-top {
		margin-top: 150px;
		padding-left: 65px;
	}

	.custom-width-container {
		width: 75%;
	}

	.paddingX {
		padding-left: 250;
		padding-right: 250;
	}

	.maxheight {
		height: 250;
	}
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}